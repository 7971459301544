<template>
  <v-dialog v-model="dialog" max-width="900px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="rounded-xl"
        outlined
        style="color: #1a347f"
      >
        <span class="text-center mx-4 fn14-weight7">
          <a-icon type="plus" class="mr-2" />
          Add New Category
        </span>
      </v-btn>
    </template>
    <v-form>
      <v-row class="pa-4" style="background: white">
        <v-col cols="8" class="mx-auto">
          <div class="fn20-weight7">Add New Category</div>
        </v-col>

        <v-col cols="8" class="mx-auto">
          <v-text-field
            v-model="form.category_name"
            hide-details
            outlined
            dense
            placeholder="New Category Name*"
          />
        </v-col>
        <v-col cols="12" class="pa-0" align="center" justify="center">
          <v-btn
            class="rounded-xl mr-2"
            style="color: #9bbef2"
            outlined
            width="250"
            @click="cancel"
          >
            <span class="text-center fn14-weight7"> cancel </span>
          </v-btn>
          <v-btn
            :dark="form.name === '' ? false : true"
            :disabled="form.name === ''"
            class="rounded-xl"
            :style="
              form.name === ''
                ? ''
                : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
            "
            width="250"
            @click="submit"
          >
            <span class="text-center fn14-weight7"> Add </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {
    form: Object,
  },
  data() {
    return {
      dialog: false,
      start: 1,

      menufrom: false,
      product: "",
      items: [],
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      picture_1: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
      selectedType: "",
    };
  },

  mounted() {
    console.log("this is current quill instance object", this.editor);
  },
  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/
          `
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
    },
    // async getAllProduct() {
    //   const response = await this.axios.get(
    //     `${process.env.VUE_APP_API}/products/findAllAdmin?brandId=` +
    //       this.brand +
    //       "&typeId=" +
    //       this.type +
    //       "&categoryId=" +
    //       this.category
    //     // 'http://localhost:8081/types/'
    //   );
    //   console.log("getAllproducts", response.data);
    //   this.items = response.data.data;
    //   for (let i in this.items) {
    //     this.items[i].count = parseInt(i) + 1;
    //     //console.log(this.list);
    //   }
    // },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.picture_1.splice(1, 1);
    },
    cancel() {
      // this.$router.push("Managebanners");
      this.start = 1;
      this.form = {
        category_name: "",
      };
      this.dialog = false;
    },
    async submit() {
      this.selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      console.log("selectedType", this.selectedType);
      // if (this.$refs.createForm.validate(true)) {
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      this.form = {
        ...this.form,
        usertype: this.selectedType,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/reward_category`,
        this.form,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          iconColor: "#1a347f",
          text: `สร้างcategoryสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        this.$emit("getAllRewardCategory");
        // this.$router.push("Managebanners");
      } else {
        this.$swal.fire({
          icon: "error",
          text: `สร้างcategoryไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
      this.start = 1;
      this.dialog = false;
      this.$emit("clear");
    },
  },
};
</script>
