<template>
  <v-form>
    <v-row align="center" justify="center" class="pa-4">
      <v-col cols="12">
        <div class="fn24-weight7">Rewards</div>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="text-right">
            <StepFormCategory
              :form.sync="form"
              @getAllRewardCategory="getAllRewardCategory"
              @clear="clear"
            />
          </v-col>
          <v-col class="text-right">
            <v-btn
              dark
              class="rounded-xl"
              style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
              @click="dialogStepFormOpen()"
            >
              <span class="text-center mx-4 fn14-weight7">
                <a-icon type="plus" class="mr-2" />
                Add New Reward
              </span>
            </v-btn>
            <StepForm
              :dialogStepForm="dialogStepForm"
              :form.sync="form1"
              :categories="categoryRewards"
              :isEdit="isEdit"
              :items="itemEdit"
              @getAllReward="getAllReward"
              @clear="clear"
              @close="closeDialog"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row justify="end">
          <v-btn
            dark
            class="rounded-xl"
            color="red"
            outlined
            @click="dialogmanagecate = true"
          >
            <span class="text-center mx-4 fn14-weight7">
              <a-icon type="minus" class="mr-2" />
              Manage Reward Category
            </span>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:header.reward_name="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.rewardImg="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.reward_category.category_name="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.point="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.redeem="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.status="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:[`item.reward_name`]="{ item }">
              <div class="fn14-weight7">{{ item.reward_name }}</div>
              <div>{{ convertDate(item.createdAt) }}</div>
            </template>
            <template v-slot:[`item.points`]="{ item }">
              <div class="fn14-weight7" style="color: #384e85">
                {{ item.points }}
              </div>
            </template>
            <template v-slot:[`item.redeem`]="{ item }">
              <div class="fn14-weight7" style="color: #384e85">
                {{ item.redeem }}
              </div>
            </template>
            <template v-slot:[`item.rewardImg`]="{ item }">
              <v-img :src="item.rewardImg" width="85" />
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-switch
                inset
                color="#1A347F"
                v-model="item.status"
                @change="Status(item)"
              />
              <!-- <v-switch
                v-if="item.status === false || !item.status"
                inset
                color="#1A347F"
                v-model="item.status"
                @change="Status(item)"
              /> -->
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <!-- <v-row justify="center"> -->
              <v-btn
                @click="UpdateRewards(item)"
                dark
                outlined
                small
                class="rounded-xl"
                style="color: #4f4f4f; width: 100px"
              >
                Edit
              </v-btn>
              <v-btn
                @click="DeleteRewards(item)"
                dark
                outlined
                small
                class="rounded-xl"
                style="color: #4f4f4f; width: 100px"
              >
                Delete
              </v-btn>
              <!-- </v-row> -->
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogmanagecate" max-width="80vw" :retain-focus="false">
      <v-card class="pa-4">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <span class="fn24-weight7">Manage Reward Category</span>
          </v-col>
          <v-col cols="8">
            <v-data-table
              :headers="headercate"
              :items="categoryRewards"
              :items-per-page="10"
              class="elevation-1"
            >
             <template v-slot:header.category_name="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
            <template v-slot:header.action="{ header }">
              <span class="fn14-weight7 black--text">{{ header.text }}</span>
            </template>
              <template v-slot:[`item.action`]="{ item }">
                <v-btn
                  @click="opendialogedit(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl"
                  style="color: #4f4f4f; width: 100px"
                >
                  Edit
                </v-btn>
                <v-btn
                  @click="DeleteRewardsCate(item)"
                  dark
                  outlined
                  small
                  class="rounded-xl"
                  style="color: #4f4f4f; width: 100px"
                >
                  Delete
                </v-btn>
              </template></v-data-table
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogeditrewardcate" max-width="900px" :retain-focus="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="rounded-xl"
          outlined
          style="color: #1a347f"
        >
          <span class="text-center mx-4 fn14-weight7">
            <a-icon type="plus" class="mr-2" />
            Edit Reward Category
          </span>
        </v-btn>
      </template>
      <v-form>
        <v-row class="pa-4" style="background: white">
          <v-col cols="8" class="mx-auto">
            <div class="fn20-weight7">Edit Reward Category</div>
          </v-col>

          <v-col cols="8" class="mx-auto">
            <v-text-field
              v-model="form.category_name"
              hide-details
              outlined
              dense
              placeholder="Category Name*"
            />
          </v-col>
          <v-col cols="12" class="pa-0" align="center" justify="center">
            <v-btn
              class="rounded-xl mr-2"
              style="color: #9bbef2"
              outlined
              width="250"
              @click="dialogeditrewardcate = false"
            >
              <span class="text-center fn14-weight7"> cancel </span>
            </v-btn>
            <v-btn
              :dark="form.category_name === '' ? false : true"
              :disabled="form.category_name === ''"
              class="rounded-xl"
              :style="
                form.category_name === ''
                  ? ''
                  : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
              "
              width="250"
              @click="submiteditcate()"
            >
              <span class="text-center fn14-weight7"> Add </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-dialog>
  </v-form>
</template>
<script>
import draggable from "vuedraggable";
import { Encode, Decode } from "@/services";
import StepForm from "@/components/Shared/stepFormRewards";
import StepFormCategory from "@/components/Shared/stepFormCategory";
import moment from "moment";
export default {
  components: {
    draggable,
    StepForm,
    StepFormCategory,
  },
  data() {
    return {
      dialogStepForm: false,
      dialogmanagecate: false,
      dialogeditrewardcate: false,
      isEdit: "",
      itemEdit: {},
      headercate: [
        // { text: "ID", value: "id", align: "center" },
        { text: "Category", value: "category_name", align: "center" },
        { text: "Action", value: "action", align: "center" },
      ],

      headers: [
        // { text: "id", value: "rewardCategoryId" },
        { text: "Title Name", value: "reward_name" },
        { text: "Images", value: "rewardImg" },
        { text: "Category", value: "reward_category.category_name" },
        { text: "Points", value: "point" },
        { text: "Redeem", value: "redeem" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", width: "150" },
      ],
      // count: 1
      // create_by: (...)
      // createdAt: (...)
      // date: (...)
      // description: (...)
      // expired_date: (...)
      // id: (...)
      // point: (...)
      // redeem: (...)
      // rewardCategoryId: (...)
      // rewardImg: (...)
      // reward_category: (...)
      // reward_name: (...)
      // status: (...)
      // update_by: (...)
      // updatedAt: (...)
      items: [
        // {
        //   name: "BLIH 10% Discount",
        //   time: "Sep 8, 2012 at 10:05 PM",
        //   image: require("@/assets/images/coupon.png"),
        //   category: "Discount Coupon",
        //   points: "30",
        //   redeem: "15",
        //   status: true,
        // },
      ],

      form: {
        category_name: "",
        id: "",
      },

      form1: {
        reward_name: "",
        description: "",
        category_name: "",
        point: "",
        date: new Date().toISOString().substr(0, 10),
        rewardImg: "",
        status: true,
      },

      category: "",
      itemsCategory: [],
      type: "",
      itemsType: [],
      brand: "",
      itemsBrand: [],
      search: "",
      count: 0,

      categoryRewards: [],
    };
  },
  created() {
    this.getAllReward();
    this.getAllRewardCategory();
  },

  methods: {
    opendialogedit(item) {
      console.log(item);
      this.dialogeditrewardcate = true;
      this.form.category_name = item.category_name;
      this.form.id = item.id;
    },
    convertDate(val) {
      return moment(val).format("MMM Do YYYY h:mm");
    },
    async dropdown() {
      const cateDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/categories`
      );
      // const typeDropdown = await this.axios.get(
      //   `${process.env.VUE_APP_API}/types`
      // );
      const brandDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`
      );
      const companyDropdown = await this.axios.get(
        `${process.env.VUE_APP_API}/companies`
      );
      this.itemsCategory = cateDropdown.data.data;
      // this.itemsType = typeDropdown.data.data;
      this.itemsBrand = brandDropdown.data.data;
      this.itemsCompany = companyDropdown.data.data;
      console.log("companyDropdown", this.itemsCompany);
    },
    async getAllReward() {
      const selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/reward?usertype=${selectedType}`
        // 'http://localhost:8081/types/'
      );
      console.log("rewardrewardrewardreward", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        this.items[i].status = parseInt(this.items[i].status);
        //console.log(this.list);
      }
    },
    async getAllRewardCategory() {
      const selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/reward_category?usertype=${selectedType}
          `
      );
      this.categoryRewards = response.data.data;
      console.log("categoryRewards", this.categoryRewards);
    },

    goToCreate() {
      this.$router.push("CreateRewards");
    },
    viewRewards(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("ViewRewards");
    },
    UpdateRewards(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.dialogStepForm = true;
      this.isEdit = "edit";
      this.itemEdit = val;
      // this.$router.push("EditRewards");
    },
    dialogStepFormOpen() {
      this.clear();
      this.itemEdit = null;
      this.dialogStepForm = true;
    },
    async DeleteRewards(val) {
      console.log(val);
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiAdmin"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/reward/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllReward();
        }
      });
    },
    async DeleteRewardsCate(val) {
      console.log(val);
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1a347f",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiAdmin"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/reward_category/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });

          await this.getAllRewardCategory();
          this.dialogmanagecate = false;
        }
      });
    },
    async submiteditcate() {
      this.selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      console.log("selectedType", this.selectedType);
      // if (this.$refs.createForm.validate(true)) {
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      // this.form = {
      //   ...this.form,
      //   category_name: this.form.category_name,
      // };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/reward_category/${this.form.id}`,
        this.form,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          iconColor: "#1a347f",
          text: `แก้ไขcategoryสำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
        await this.getAllRewardCategory();
        this.dialogeditrewardcate = false;
      } else {
        this.$swal.fire({
          icon: "error",
          text: `แก้ไขcategoryไม่สำเร็จ`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.dialogeditrewardcate = false;
      this.form.category_name = "";
    },
    async Status(val) {
      console.log("item", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiAdmin")));
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      if (val.status) {
        val.status = 1;
      } else {
        val.status = 0;
      }
      const data = {
        reward_name: val.reward_name,
        status: val.status,
      };
      console.log("item", val.id);
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/reward/${val.id}`,
        data,
        auth
      );
    },
    closeDialog() {
      this.dialogStepForm = false;
      this.itemEdit = null;
    },
    clear() {
      this.form = {
        category_name: "",
      };

      this.form1 = {
        reward_name: "",
        description: "",
        category_name: "",
        point: "",
        date: new Date().toISOString().substr(0, 10),
        rewardImg: "",
        status: true,
      };
    },
  },
};
</script>
