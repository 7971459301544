<template>
  <div>
    <ManageRewards />
  </div>
</template>
<script>
import ManageRewards from "@/components/Rewards/ManageRewards";
export default {
  components: {
    ManageRewards,
  },
  created() {},
};
</script>
