<template>
  <v-dialog v-model="dialogStepForm" max-width="900px">
    <!-- <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        v-bind="attrs"
        v-on="on"
        class="rounded-xl"
        style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
      >
        <span class="text-center mx-4 fn14-weight7">
          <a-icon type="plus" class="mr-2" />
          Add New Reward
        </span>
      </v-btn>
    </template> -->
    <v-form>
      <v-row class="pa-4" style="background: white">
        <v-col cols="6">
          <div class="fn20-weight7">
            {{ isEdit ? "Edit Reward" : "Create New Reward" }}
          </div>
        </v-col>

        <v-stepper v-model="start" vertical width="100%">
          <v-stepper-step :complete="start > 1" step="1" class="fn16">
            Reward Information
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-row style="color: #1a347f" class="fn16">
              <v-col cols="12" class="pb-0"> Reward Name*: </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="form.reward_name"
                  hide-details
                  outlined
                  dense
                  placeholder="type your title here"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" class="pb-0">Description*:</v-col>
              <v-col cols="12" class="pb-0">
                <v-textarea
                  v-model="form.description"
                  rows="3"
                  hide-details
                  outlined
                  dense
                  placeholder="Description"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" class="pb-0">Choose Category*:</v-col>
              <v-col cols="12" class="pb-0">
                <v-autocomplete
                  v-model="form.rewardCategoryId"
                  hide-details
                  outlined
                  dense
                  :items="categories"
                  item-text="category_name"
                  item-value="id"
                  placeholder="Select category"
                  :rules="rules.required"
                />
              </v-col>
              <v-col cols="12" class="pb-0">Point*:</v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="form.point"
                  outlined
                  dense
                  type="number"
                  placeholder="0-999"
                  :rules="rules.required"
                />
              </v-col>
            </v-row>
            <v-btn
              color="primary"
              @click="start = 2"
              :disabled="
                !form.reward_name ||
                !form.description ||
                !form.rewardCategoryId ||
                !form.point
              "
            >
              Continue
            </v-btn>
          </v-stepper-content>

          <v-stepper-step :complete="start > 2" step="2">
            Images 300 x 300 px
          </v-stepper-step>

          <v-stepper-content step="2">
            <v-col cols="12" class="pa-0">
              <v-row no-gutters align="center" justify="center">
                <v-col cols="12" class="mt-5 ma-10" id="step-3">
                  <v-img
                    v-model="form.rewardImg"
                    @click="changePic1()"
                    src="@/assets/icons/upload.png"
                    class="mr-3 d-inline-block"
                    max-width="18"
                    max-height="22"
                  >
                  </v-img>
                  <span
                    style="color: #1a347f"
                    class="fn16-weight7"
                    @click="changePic1()"
                  >
                    Upload files
                  </span>
                  <br />
                  <span style="color: red" class="fn14"
                    >ใช้ขนาด 300x300 px
                  </span>
                  <v-col
                    cols="12"
                    md="3"
                    class="pl-5 pr-5 row fill-height align-center sortable-list"
                  >
                    <v-card dense light class="pa-1" v-if="showImage">
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-icon small light @click="RemoveImage"
                          >mdi-close</v-icon
                        >
                      </v-card-actions>

                      <v-img
                        :src="showImage"
                        width="200px"
                        aspect-ratio="1.8"
                        contain
                      ></v-img>
                    </v-card>
                  </v-col>
                  <input
                    type="file"
                    ref="image"
                    id="picTure1"
                    accept="image/*"
                    style="display: none"
                    hidden
                    @change="showPicture1($event)"
                /></v-col>
              </v-row>
            </v-col>
            <v-btn
              color="primary"
              class="mr-2"
              @click="start = 3"
              :disabled="!showImage"
            >
              Continue
            </v-btn>
            <v-btn @click="start = 1"> Cancel </v-btn>
          </v-stepper-content>
        </v-stepper>
        <v-col cols="12" class="" align="center" justify="center">
          <v-row>
            <v-col cols="12">
              <v-btn
                class="rounded-xl mr-2"
                style="color: #384e85"
                outlined
                width="250"
              >
                <span class="text-center fn14-weight7"> Preview </span>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn
                class="rounded-xl mr-2"
                style="color: #9bbef2"
                outlined
                width="250"
                @click="cancel"
              >
                <span class="text-center fn14-weight7"> cancel </span>
              </v-btn>
              <v-btn
                :dark="start < 3 ? false : true"
                :disabled="start < 3"
                class="rounded-xl"
                :style="
                  start < 3
                    ? ''
                    : 'background: linear-gradient(268.1deg, #07204b, #1a347f)'
                "
                width="250"
                @click="submit"
              >
                <span class="text-center fn14-weight7"> Upload </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },
  props: {
    dialogStepForm: Boolean,
    form: Object,
    categories: Array,
    isEdit: {
      type: String,
      default: "",
    },
    items: Object,
  },
  data() {
    return {
      dialogStepForm: false,
      start: 1,

      menufrom: false,
      product: "",
      title: "",
      categoryDescription: "",
      activeFlag: true,
      pic1: "",
      rewardImg: "",
      showImage: "",
      imageName1: "",
      rules: {
        required: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      checkValidate: true,
      lazy: false,
      content: "<br/><br/><br/><br/><br/>",
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ color: [] }, { background: [] }],
            [{ align: [] }],
            ["clean"],
            [
              "link",
              // "image",
              // "video"
            ],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      categoryRewards: [],
      idEdit: "",
    };
  },
  watch: {
    items: {
      handler(val) {
        if (this.isEdit === "edit") {
          this.getRewardById(val.id);
        } else {
          this.$emit("clear");
        }
      },
      deeps: true,
    },
  },

  methods: {
    onEditorChange({ quill, html, text }) {
      console.log("editor change!", quill, html, text);
      this.content = html;
    },
    async showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;

      if (files[0] !== undefined) {
        this.pic1 = files[0].name;
        const element = files[0];
        // const reader = new FileReader();
        // reader.readAsDataURL(element);
        // reader.onload = () => {
        //   this.pic1 = reader.result;
        //   this.showImage = URL.createObjectURL(element);
        // };
        console.log(element);

        var user = JSON.parse(
          Decode.decode(localStorage.getItem("userBiAdmin"))
        );
        const auth = {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        };

        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/reward/upload-url-img/?contentType=${element.type}&image_name=${element.name}`,
          auth
        );

        console.log("response", response);
        console.log("url", response.data.url);
        const responseUrl = await this.axios.put(
          `${response.data.url}`,
          element
        );
        console.log("responseUrl", responseUrl);
        this.showImage = response.data.fullUrl;
        // /reward/upload-url-img/?contentType=jpg&image_name=TEST_IMG
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    onPickFile2() {
      document.getElementById("file_input2").click();
    },
    RemoveImage() {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      this.form.rewardImg.splice(1, 1);
    },
    cancel() {
      if (this.isEdit) {
        this.start = 1;
        this.showImage = "";
        this.$emit("close");
      } else {
        this.start = 1;
        // this.form = {
        //   reward_name: "",
        //   description: "",
        //   rewardCategoryId: "",
        //   point: "",
        //   date: new Date().toISOString().substr(0, 10),
        //   rewardImg: "",
        // };
        this.showImage = "";
        this.$emit("close");
        this.$emit("clear");
      }
    },
    async submit() {
      const selectedType = JSON.parse(
        Decode.decode(localStorage.getItem("selectedType"))
      );
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const category = this.categories.filter(
        (o) => o.id === this.form.rewardCategoryId
      )[0];
      const categoryId = category.id;
      this.form = {
        ...this.form,
        rewardCategoryId: categoryId,
        rewardImg: this.showImage,
        usertype: selectedType,
        status:1,
      };
      if (this.isEdit === "edit") {
        this.form = {
          ...this.form,
          id: this.idEdit,
        };
      }
      console.log("this.form", this.form);
      if (this.isEdit === "edit") {
        const response = await this.axios.put(
          `${process.env.VUE_APP_API}/reward/` + this.idEdit,
          this.form,
          auth
        );
        if (response.data.response_status === "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            iconColor: "#1a347f",
            text: `แก้ไขrewardสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllReward");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `แก้ไขrewardไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.showImage = "";
        this.$emit("close");
        this.$emit("clear");
      } else {
        const response = await this.axios.post(
          `${process.env.VUE_APP_API}/reward`,
          this.form,
          auth
        );
        console.log("creatPost", response);
        if (response.data.response_status === "SUCCESS") {
          this.$swal.fire({
            icon: "success",
            iconColor: "#1a347f",
            text: `สร้างrewardสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.$emit("getAllReward");
        } else {
          this.$swal.fire({
            icon: "error",
            text: `สร้างrewardไม่สำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.start = 1;
        this.showImage = "";
        this.$emit("close");
        this.$emit("clear");
      }
    },
    async getRewardById(id) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/reward/` + id
      );
      const { data } = response.data;
      console.log("responseById", data);
      this.form.reward_name = data.reward_name;
      this.form.description = data.description;
      this.form.rewardCategoryId = data.rewardCategoryId;
      this.form.point = data.point;
      this.form.rewardImg = data.rewardImg;
      this.showImage = data.rewardImg;
      this.idEdit = data.id;
    },
  },
};
</script>
